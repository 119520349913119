import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import globalContext from '../context/products/globalContext';
import Button from '../components/Button';
import Products from '../components/Products';

const Home = () => {
  const navigate = useNavigate();

  const GlobalContext = useContext(globalContext);

  const { deleteProducts, checkedItems, products, listProducts } =
    GlobalContext;

  const onClickDeleteItems = () => {
    if (checkedItems.length > 0) deleteProducts(checkedItems);
  };

  useState(() => {
    if (products) {
      listProducts();
    }
  }, [products]);

  return (
    <div className='container'>
      <nav className='navbar'>
        <h2>Product List</h2>
        <div className='btn-container'>
          <Button text='ADD' click={() => navigate('/add-product')} />
          <Button
            text='MASS DELETE'
            click={() => onClickDeleteItems()}
            id='delete-product-btn'
          />
        </div>
      </nav>
      <main>
        <Products />
      </main>
    </div>
  );
};

export default Home;
