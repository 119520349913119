import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import Form from '../components/Form';
import globalContext from '../context/products/globalContext';

const AddProducts = () => {
  const navigate = useNavigate();

  const GlobalContext = useContext(globalContext);

  const { products, listProducts } = GlobalContext;

  const onClickHandeler = () => {
    navigate('/');
  };

  useState(() => {
    if (products) {
      listProducts();
    }
  }, [products]);

  return (
    <div className='container'>
      <nav className='navbar'>
        <h2>Add Product</h2>
        <div className='btn-container'>
          <Button text='SAVE' form='product_form' />
          <Button text='CANCEL' click={() => onClickHandeler()} />
        </div>
      </nav>
      <main className='container'>
        <Form />
      </main>
    </div>
  );
};

export default AddProducts;
