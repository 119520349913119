import React, { useReducer } from 'react';
import productsReducer from './productsReducer';
import GlobalContext from './globalContext';
import axios from 'axios';
import { localhost } from '../../config';
import { useNavigate } from 'react-router-dom';

// Initial State

const initialState = {
  products: [],
  checkedItems: [],
  error: null,
};

// Provider Component

export const ProductsState = ({ children }) => {
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(productsReducer, initialState);

  const listProducts = async () => {
    try {
      const res = await axios.get(`${localhost}/read.php`);

      dispatch({
        type: 'LIST_PRODUCTS',
        payload: res.data.data,
      });
    } catch (error) {
      console.log(error);

      dispatch({
        type: 'ERROR_MESSAGE',
        payload: error.message && error.message,
      });
    }
  };

  const deleteProducts = async (data) => {
    try {
      const res = await axios.delete(`${localhost}/delete.php`, {
        data,
      });

      addCheckedItems([]);
      listProducts();

      return res;
    } catch (error) {
      dispatch({
        type: 'ERROR_MESSAGE',
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  const createProducts = async (data) => {
    try {
      const res = await axios.post(`${localhost}/create.php`, data);

      navigate('/');

      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const addCheckedItems = (data) => {
    dispatch({ type: 'CHECKED_PRODUCTS', payload: data });
  };

  return (
    <GlobalContext.Provider
      value={{
        products: state.products,
        checkedItems: state.checkedItems,
        listProducts,
        deleteProducts,
        createProducts,
        dispatch,
        addCheckedItems,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
