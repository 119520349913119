import React, { useContext, useEffect, useState } from 'react';
import DynamicForm from './DynamicForm';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import globalContext from '../context/products/globalContext';

const Form = () => {
  const GlobalContext = useContext(globalContext);

  const { createProducts, products } = GlobalContext;

  const [selects, setSelects] = useState('');
  const [multipleFieldShow, setMultipleFieldShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorAllFields, setErrorAllFields] = useState(false);

  // Validation with yup

  const schema = yup.object().shape({
    sku: yup.string().required('Please choose a SKU.'),
    name: yup.string().required('Please choose a name.'),
    price: yup
      .number()
      .required('Please set a valid price.')
      .positive()
      .typeError('Please set a valid price.'),
    category: yup.string().required('Please add product type'),
    size: multipleFieldShow
      ? yup.object({
          height: yup.string().required('Please set a valid height.'),
          width: yup.string().required('Please set a valid width.'),
          length: yup.string().required('Please set a valid length.'),
        })
      : yup.string().required('Please enter valid number'),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onChangeHandeler = (value) => {
    if (value === 'furniture') {
      setMultipleFieldShow(true);
    } else {
      setMultipleFieldShow(false);
    }

    watch(value);
    setSelects(value);
  };

  const onSubmit = (data) => {
    let alreadyExists = false;

    // eslint-disable-next-line
    products?.map((item) => {
      if (item.sku === data.sku) {
        setErrorMessage(true);

        setTimeout(() => {
          setErrorMessage(false);
        }, 3000);
        alreadyExists = true;
      }
    });

    if (!alreadyExists) {
      setErrorMessage(false);
      if (data.size.width) {
        const dimension =
          data.size.height + 'x' + data.size.width + 'x' + data.size.length;

        createProducts({ ...data, size: dimension, selects });
      } else createProducts({ ...data });
    } else {
    }
  };

  const errorStyle = {
    color: '#FF0000',
    fontSize: '12px',
    marginLeft: '10px',
    marginTop: '5px',
  };

  useEffect(() => {
    if (errors) {
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          setErrorAllFields(true);
        }
      }
    }
  }, [errors, setErrorAllFields]);

  return (
    <form
      className='form'
      id='product_form'
      onSubmit={handleSubmit((data) => onSubmit(data))}
    >
      <div className='form-control'>
        <label htmlFor='' className='label'>
          Sku
        </label>
        <div>
          <input
            placeholder='SKU000'
            name='Sku'
            type='text'
            id='sku'
            {...register('sku', { required: errors.sku?.message })}
          />
          {errors && <p style={errorStyle}>{errors.sku?.message}</p>}
          {errorMessage && <p style={errorStyle}>SKU item already exist</p>}
        </div>

        <p style={{ marginLeft: '10px', fontSize: '12px' }}>
          Stock Keeping Unit needs to be unique.
        </p>
      </div>
      <div className='form-control'>
        <label htmlFor='' className='label'>
          Name
        </label>
        <div>
          <input
            placeholder='name'
            type='text'
            name='Name'
            id={'name'}
            {...register('name', { required: errors.name?.message })}
          />
          {errors && <p style={errorStyle}>{errors.name?.message}</p>}
        </div>
        <p style={{ marginLeft: '10px', fontSize: '12px' }}>Name of Product</p>
      </div>
      <div className='form-control'>
        <label htmlFor='' className='label' id='price'>
          Price: $
        </label>
        <div>
          <input
            placeholder='price'
            type='number'
            name='Price'
            id='price'
            {...register('price', { required: errors.price?.message })}
          />
          {errors && <p style={errorStyle}>{errors.price?.message}</p>}
        </div>
        <p style={{ marginLeft: '10px', fontSize: '12px' }}>
          Price needs to be a positive number.
        </p>
      </div>
      <div style={{ padding: '10px 0' }}>
        <label htmlFor='' className='label'>
          Type Switcher:{' '}
        </label>

        <select
          name='Type Switcher:'
          id='productType'
          {...register('category', { required: true })}
          onChange={(e) => onChangeHandeler(e.target.value, { watch: true })}
        >
          <option value=''>Product Type</option>

          <option value='dvd'>DVD</option>

          <option value='furniture'>Furniture</option>

          <option value='book'>Book</option>
        </select>
        {errors && <p style={errorStyle}>{errors.category?.message}</p>}
      </div>
      <DynamicForm selects={selects} errors={errors} register={register} />
      {errorAllFields && (
        <p
          style={{
            ...errorStyle,
            marginTop: '20px',
            fontSize: '15px',
            marginLeft: '0',
          }}
        >
          Please, submit required data
        </p>
      )}
    </form>
  );
};

export default Form;
