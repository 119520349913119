// eslint-disable-next-line
export default (state, action) => {
  // eslint-disable-next-line
  switch (action.type) {
    case 'ERROR_MESSAGE':
      return {
        ...state,
        error: action.payload,
      };

    case 'SET_LOADING':
      return {
        ...state,
        error: null,
      };

    case 'LIST_PRODUCTS':
      return {
        ...state,
        products: action.payload,
      };

    case 'ADD_PRODUCTS':
      return {
        ...state,
        products: action.payload,
      };

    case 'DELETE_PRODUCTS':
      return {
        ...state,
      };
    case 'CHECKED_PRODUCTS':
      return {
        ...state,
        checkedItems: action.payload,
      };
  }
};
