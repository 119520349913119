import React from 'react';

const Button = ({ text, click, className, form, id }) => {
  return (
    <button onClick={click} className={className} form={form} id={id}>
      {text}
    </button>
  );
};

export default Button;
