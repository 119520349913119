import './style/base.scss';
import Home from './pages/Home';
import { Routes, Route } from 'react-router-dom';
import AddProducts from './pages/AddProducts';
import Footer from './layout/Footer';

const App = () => {
  return (
    <div className='wrapper'>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/add-product' element={<AddProducts />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
