import React, { useCallback } from 'react';

const Product = ({ item, setCheckedItems }) => {
  let toggleValue = useCallback(
    (event, id) => {
      if (event.target.checked) {
        setCheckedItems((value) => [...value, { id }]);
      } else {
        setCheckedItems((value) => value.filter((it) => it.id !== id));
      }
    },
    [setCheckedItems]
  );

  return (
    <div className='product'>
      <div>
        <input
          type='checkbox'
          name='delete-checkbox'
          onChange={(e) => toggleValue(e, item.id)}
          className='delete-checkbox'
          value={item.id}
        />
      </div>
      <p style={{ padding: '5px' }}>{item.sku}</p>
      <h3>{item.name}</h3>
      <p>{item.price}$</p>
      <p>{item.productType}</p>
      <p>{item.size}</p>
      <p>
        {item.category === 'dvd'
          ? 'MB'
          : item.category === 'book'
          ? 'KG'
          : 'CM'}
      </p>
    </div>
  );
};

export default Product;
