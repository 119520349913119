import React, { useContext, useEffect, useState } from 'react';
import Product from './Product';
import globalContext from '../context/products/globalContext';

const Products = () => {
  const GlobalContext = useContext(globalContext);

  const { products, addCheckedItems } = GlobalContext;

  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    addCheckedItems(checkedItems);
    // eslint-disable-next-line
  }, [checkedItems]);

  return (
    <div className='products'>
      {products?.map((item) => (
        <Product
          item={item}
          key={item.sku}
          setCheckedItems={setCheckedItems}
          checkedItems={checkedItems}
        />
      ))}
    </div>
  );
};

export default Products;
